import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "./store";
import {appActions} from "./appReducer";
import AppsService from "./services/AppsService";
import {AppType} from "./models/types";


const initialState: AppType[] = []

export type AppsInitialStateType = typeof initialState;

const slice = createSlice({
    name: "apps",
    initialState,
    reducers: {
        setAppsData: (state: AppsInitialStateType, action: PayloadAction<{ data: AppType[] }>) => {

            return action.payload.data
        },
    },
});

export const appsReducer = slice.reducer;
export const appsActions = slice.actions;

export const fetchAppsTC = (): AppThunk =>
    (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));

        AppsService.fetchApps().then(res => {

            dispatch(appsActions.setAppsData({data: res.data}));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        }).catch(err => {
            dispatch(appActions.setAppStatus({status: "failed"}));
            console.log('-----------------------------')
            console.log(err.message)
        })

    }
