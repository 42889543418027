import $api from "../http";
import axios, {AxiosResponse} from "axios";
import {AuthResponse} from "../models/AuthResponse";


export default class AuthService {
    static async login(username: string, password: string): Promise<AxiosResponse<AuthResponse>> {
        return $api.post<AuthResponse>('/login', {username, password})
    }

    static async logout(refresh_token: string | null): Promise<void> {
        return $api.post('/logout', {refresh_token})
    }

    static async refresh(refresh_token: string | null): Promise<AxiosResponse<AuthResponse>> {
        return axios.post<AuthResponse>(`${process.env.REACT_APP_API_URL}/token/refresh`,
            {refresh: refresh_token},
            {withCredentials: true})
    }

    static async apps(): Promise<void> {
        return $api.get('/apps')
    }
}

