import $api from "../http";
import {AxiosResponse} from "axios";
import {CampaignType, SubmittedCampaignsСhangesType, TakeAppRentDataType} from "../models/types";


export default class CampaignsService {

    static async sendTakenCampaign(data: TakeAppRentDataType): Promise<AxiosResponse<CampaignType>> {
        return $api.post('/campaigns', data)
    }

    static async fetchAllCampaigns(): Promise<AxiosResponse<CampaignType[]>> {
        return $api.get('/campaigns')
    }

    static async fetchDefiniteCampaign(id: string | null): Promise<AxiosResponse<CampaignType>> {
        return $api.get('/campaigns', {
            params: id
        })
    }

    static async sentCampaignChanges(id: string | null, data: SubmittedCampaignsСhangesType): Promise<AxiosResponse<CampaignType>> {
        return $api.put(`/campaigns/${id}`, data)
    }
}

