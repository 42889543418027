import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "./store";
import {appActions} from "./appReducer";
import StatisticsService, {
    StatisticsParamsType,
    StatisticsResponseType,
} from "./services/StatisticsService";

type InitialStateType = {
    statistics: StatisticsResponseType
    filters: StatisticsParamsType
}
const initialState: InitialStateType = {
    statistics: {
        next: '',
        previous: null,
        count: 0,
        total_pages: 0,
        page: 1,
        page_size: 10,
        results: [],
        total: []
    },
    filters: JSON.parse(localStorage.getItem('statisticFilters') || JSON.stringify({}))
};


const slice = createSlice({
    name: "statistics",
    initialState,
    reducers: {
        setStatisticsData: (state: InitialStateType, action: PayloadAction<{ data: StatisticsResponseType }>) => {
            state.statistics = action.payload.data
        },
        setFilters: (state: InitialStateType, action: PayloadAction<StatisticsParamsType>) => {
            // debugger
            state.filters = action.payload
            localStorage.setItem('statisticFilters', JSON.stringify(action.payload))
            // debugger
        },
        resetForm:(state: InitialStateType, action: PayloadAction<StatisticsParamsType>)=> {
            state.filters = action.payload
        }
    },
});

export const statisticsReducer = slice.reducer;
export const statisticsActions = slice.actions;

export const fetchStatisticsTC = (data: StatisticsParamsType): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(appActions.setAppStatus({status: "loading"}));
            const res = await StatisticsService.fetchStatistics(data)
            dispatch(statisticsActions.setStatisticsData({data: res.data}));

            dispatch(appActions.setAppStatus({status: "succeeded"}));
        } catch
            (err) {
            dispatch(appActions.setAppStatus({status: "failed"}));
        } finally {
            dispatch(appActions.setAppInitialized({isInitialized: true}));
        }

    }

