import React, {useEffect} from "react";
import {Modal, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import style from "./ModalTransactionInfo.module.scss";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {useSelector} from "react-redux";
import {transactionInfoState} from "../../state/modalEditRentState";
import {fetchInfoAboutTransactionTC, transactionActions} from "../../state/transactions-reducer";
import {modalsActions} from "../../state/modals-reducer";
import {ReactComponent as Cross} from "../../assets/images/Cross.svg";
import {AppRootStateType} from "../../state/store";
import {TransactionInfoType} from "../../state/models/types";

export const ModalTransactionInfo: React.FC = () => {
    const dispatch = useAppDispatch();
    const stateOfModal = useSelector(transactionInfoState);
    const transaction = useSelector<AppRootStateType, TransactionInfoType>(state => state.transactions.transactionInfo);

    useEffect(() => {
        if (stateOfModal) {
            dispatch(fetchInfoAboutTransactionTC(stateOfModal))
        }
    }, [stateOfModal]);

    const toggleModal = () => {
        dispatch(modalsActions.changeStateOfTransactionInfo(null))
        dispatch(transactionActions.setTransInfo({
            id: '',
            created_at: '',
            finalized_at: '',
            hash: '',
            amount: 0,
            bonus: 0,
            currency: '',
            chain: '',
            status: '',
            comment: '',
            error_code: 0,
            client: 0,
        }))
    }


    const date = transaction.created_at && new Date(transaction.created_at).toLocaleDateString('Ru')
    const time = transaction.created_at && new Date(transaction.created_at).toLocaleTimeString('Ru').slice(0, -3)

    return (

        <Modal open={!!stateOfModal} className={style.Canvas}>

            <div className={style.Wrapper}>
                <div className={style.Container}>
                    <div className={style.TitleBlock}>
                        <h3 className={style.Title}>Информация о транзакции</h3>
                        <button onClick={toggleModal}>
                            <Cross/>
                        </button>
                    </div>
                    <TableContainer sx={{maxHeight: 640,}}>
                        <Table className={style.Table} sx={{minWidth: 650}}>

                            <TableBody>
                                <TableRow>
                                    <TableCell className={style.TableCell}>
                                        Дата
                                    </TableCell>
                                    <TableCell className={style.TableCell}>
                                        {date} {time}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={style.TableCell}>
                                        Статус
                                    </TableCell>
                                    <TableCell className={style.TableCell}>
                                        {transaction.status}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={style.TableCell}>
                                        Сумма
                                    </TableCell>
                                    <TableCell className={style.TableCell}>
                                        {transaction.amount === 0 && ''} USDT
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={style.TableCell}>
                                        Tx Hash
                                    </TableCell>
                                    <TableCell className={style.TableCell}>
                                        {transaction.hash}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={style.TableCell} style={{width: '170px'}}>
                                        Причина ошибки
                                    </TableCell>
                                    <TableCell className={style.TableCell} style={{maxWidth: "550px"}}>
                                        {transaction.comment}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </Modal>
    )
}