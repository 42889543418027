import {lazy} from 'react';
import {RoutesType} from "../state/types";


const Catalog = lazy(() => import('../pages/catalog/Catalog'));
const Applications = lazy(() => import('../pages/mainApps/MainApps'));
const Statistics = lazy(() => import('../pages/statistics/Statistics'));
const Tokens = lazy(() => import('../pages/tokens/Tokens'));
const Logs = lazy(() => import('../pages/logs/Logs'));
const PushNotifications = lazy(() => import('../pages/pushNotifications/PushNotifications'));
const Profile = lazy(() => import('../pages/profile/Profile'));
const BalancePage = lazy(() => import('../pages/balancePage/BalancePage'));
const NotFound = lazy(() => import('../components/notFound/NotFound'));


const routes: RoutesType[] = [
    {path: '/catalog', element: Catalog},
    {path: '/applications', element: Applications},
    {path: '/statistics', element: Statistics},
    {path: '/tokens', element: Tokens},
    {path: '/logs', element: Logs},
    {path: '/pushNotifications', element: PushNotifications},
    {path: '/profile', element: Profile},
    {path: '/balance', element: BalancePage},
    {path: '/404', element: NotFound},
]

export default routes
