import $api from "../http";
import {AxiosResponse} from "axios";
import {NotifyType} from "../profileReducer";

export type StatisticsType = {
    date?: string
    clicks: number | null
    installs: number | null
    registrations: number | null
    deposits: number | null
    cr_install: number | null
    cr_registration: number | null
    cr_deposit: number | null
    cost: number | null
}

export type StatisticsResponseType = {
    next: string,
    previous: null,
    count: number,
    total_pages: number,
    page: number,
    page_size: number,
    results: StatisticsType[],
    total: StatisticsType[]
}

export type StatisticsParamsType = {
    app_id?: string
    campaign_id?: string
    end_date?: string | null  | Date
    start_date?: string | null | Date
    page?: number
    page_size?: number
}

export default class StatisticsService {
    static async fetchStatistics(data: StatisticsParamsType): Promise<AxiosResponse<StatisticsResponseType>> {
        return $api.get('/statistics', {
            params: data
        })
    }
}

