import {Route, Routes} from "react-router-dom";
import React, {lazy} from "react";
import routes from "../../routes/routes";

const NotFound = lazy(() => import('../../components/notFound/NotFound'));
const MainContent = () => {
    return (
        <>
            <Routes>
                {
                    routes.map((route, idx) => {
                        return (
                            route.element && (
                                <Route
                                    key={idx}
                                    path={route.path}
                                    element={<route.element/>}
                                />
                            )
                        )
                    })}
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </>
    )
}
export default MainContent