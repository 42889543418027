import $api from "../http";
import {AxiosResponse} from "axios";
import {NotifyType} from "../profileReducer";

type ProfileTypeResponse = {
    email: string
    send_balance_notifications: boolean
}
export default class ProfileService {
    static async fetchProfile(): Promise<AxiosResponse<ProfileTypeResponse>> {
        return $api.get('/profile')
    }

    static async sentStatusOfBalanceNotifications(data: boolean): Promise<AxiosResponse<ProfileTypeResponse>> {
        return $api.put('/profile', {
            send_balance_notifications: data
        })
    }

    static async fetchProfileNotifications(): Promise<AxiosResponse<NotifyType>> {
        return $api.get('/profile/notifications')
    }

    static async sentProfileNotifications(data:NotifyType): Promise<AxiosResponse<NotifyType>> {
        return $api.put('/profile/notifications', data)
    }
}

