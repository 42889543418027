import $api from "../http";
import {AxiosResponse} from "axios";
import {TransactionType} from "../transactions-reducer";
import {TransactionInfoType} from "../models/types";

export type TransactionPostDataType = {
    chain: string
    hash: string
}
export type BonusesType = {
    amount: number
    bonus: number
}
export type InfoDepositType = {
    address: string
    bonuses: BonusesType[]
}
export type TransactionResponseStateType = {
    count: number,
    total_pages: number,
    page: number,
    page_size: number,
    results: TransactionType[]

}

export default class TransactionsService {
    static async fetchTransactions(currentPage: number): Promise<AxiosResponse<TransactionResponseStateType>> {
        return $api.get('/transactions/', {
            params: {
                page: currentPage,
                page_size: 5
            }
        })
    }

    static async fetchDepositInfo(): Promise<AxiosResponse<InfoDepositType>> {
        return $api.get('/transactions/deposit_info')
    }

    static async fetchInfoAboutTransaction(id: string | null): Promise<AxiosResponse<TransactionInfoType>> {
        return $api.get(`/transactions/${id}`)
    }

    static async sentDepositInfo(data: TransactionPostDataType): Promise<AxiosResponse<TransactionInfoType>> {
        return $api.post('/transactions/', data)
    }
}

