import {getAccessToken, getRefreshToken, removeToken, setToken} from "../utils/utils";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "./store";
import {appActions} from "./appReducer";
import AuthService from "./services/AuthService";
import axios from "axios";
import {AuthResponse} from "./models/AuthResponse";


const slice = createSlice({
    name: "auth",
    initialState: {
        isLoggedIn: false
    },
    reducers: {
        setIsLoggedIn: (state, action: PayloadAction<{ isLoggedIn: boolean }>) => {
            state.isLoggedIn = action.payload.isLoggedIn;
        },
    },
});
export const authReducer = slice.reducer;
export const authActions = slice.actions;


export const loginTC = (email: string, password: string): AppThunk =>
    (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        AuthService.login(email, password).then((res) => {
            setToken(res.data);
            dispatch(authActions.setIsLoggedIn({isLoggedIn: true}));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        })
            .catch(err => {
                console.log('------------------fail-----------')
                console.log(err.message)
                dispatch(appActions.setAppStatus({status: "incorrectValues"}));
            });
    };

export const checkAuthTC = (): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(appActions.setAppStatus({status: "loading"}));
            const response = await AuthService.refresh(getRefreshToken())
            setToken(response.data)
            dispatch(authActions.setIsLoggedIn({isLoggedIn: true}));
        } catch (err) {
            console.log('---------------------------')
            dispatch(appActions.setAppStatus({status: "failed"}));
        } finally {
            dispatch(appActions.setAppInitialized({isInitialized: true}));
        }
    };

export const appTC = (): AppThunk =>
    (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        AuthService.apps().then((res) => {
            dispatch(authActions.setIsLoggedIn({isLoggedIn: true}));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        })
            .catch(err => {
                console.log('-----------------------------')
                console.log(err.message)
            });
    };


export const logoutTC = (): AppThunk =>
    (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        AuthService.logout(getAccessToken()).then((res) => {
            removeToken()
            dispatch(authActions.setIsLoggedIn({isLoggedIn: false}));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        })
            .catch(err => {
                console.log(err.response.data.data.message)
            });
    };

export const setIsLoggedInAC = (value: boolean) =>
    ({type: 'login/SET-IS-LOGGED-IN', value} as const)


