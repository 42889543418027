import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "./store";
import {appActions} from "./appReducer";
import TransactionsService, {
    InfoDepositType,
    TransactionPostDataType,
    TransactionResponseStateType
} from "./services/TransactionsService";
import {TransactionInfoType} from "./models/types";

export type TransactionType = {
    id: string,
    created_at: string,
    finalized_at: string,
    hash: string,
    amount: number,
    bonus: number,
    currency: string,
    chain: string,
    status: string,
    comment: string,
    error_code: number,
    client: number
}
type StateType = {
    trans: TransactionResponseStateType
    info: InfoDepositType
    transactionInfo: TransactionInfoType | null
}
const initialState = {
    trans: {
        count: 0,
        total_pages: 0,
        page: 0,
        page_size: 0,
        results: [
            {
                id: '',
                created_at: '',
                finalized_at: '',
                hash: '',
                amount: 0,
                bonus: 0,
                currency: '',
                chain: '',
                status: '',
                comment: '',
                error_code: 0,
                client: 0
            },
        ],
    },
    info: {
        address: '',
        bonuses: [{
            amount: 10,
            bonus: 10,
        }],
    },
    transactionInfo: {
        id: '',
        created_at: '',
        finalized_at: '',
        hash: '',
        amount: 0,
        bonus: 0,
        currency: '',
        chain: '',
        status: '',
        comment: '',
        error_code: 0,
        client: 0,
    }

}
export type TransactionStateType = typeof initialState;

const slice = createSlice({
    name: "transactions",
    initialState,
    reducers: {
        setTransactionData: (state: StateType, action: PayloadAction<TransactionResponseStateType>) => {
            state.trans = action.payload
        },
        setDepositInfo: (state: StateType, action: PayloadAction<InfoDepositType>) => {
            state.info = action.payload
        },
        setTransInfo: (state: StateType, action: PayloadAction<TransactionInfoType | null>) => {
            state.transactionInfo = action.payload
        },
        setNewTransaction: (state: StateType, action: PayloadAction<TransactionType>) => {
            state.trans.results.unshift(action.payload)
        },

    },
});

export const transactionReducer = slice.reducer;
export const transactionActions = slice.actions;

export const fetchTransactionTC = (currentPage: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(appActions.setAppStatus({status: "loading"}));
            const res = await TransactionsService.fetchTransactions(currentPage)

            dispatch(transactionActions.setTransactionData(res.data));

            dispatch(appActions.setAppStatus({status: "succeeded"}));
        } catch
            (err) {
            dispatch(appActions.setAppStatus({status: "failed"}));
        } finally {
            dispatch(appActions.setAppInitialized({isInitialized: true}));
        }

    }

export const fetchDepositInfoTC = (): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(appActions.setAppStatus({status: "loading"}));
            const res = await TransactionsService.fetchDepositInfo()

            dispatch(transactionActions.setDepositInfo(res.data));

            dispatch(appActions.setAppStatus({status: "succeeded"}));
        } catch
            (err) {
            dispatch(appActions.setAppStatus({status: "failed"}));
        } finally {
            dispatch(appActions.setAppInitialized({isInitialized: true}));
        }

    }

export const fetchInfoAboutTransactionTC = (id: string | null): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(appActions.setAppStatus({status: "loading"}));
            const res = await TransactionsService.fetchInfoAboutTransaction(id)

            dispatch(transactionActions.setTransInfo(res.data));

            dispatch(appActions.setAppStatus({status: "succeeded"}));
        } catch
            (err) {
            dispatch(appActions.setAppStatus({status: "failed"}));
        } finally {
            dispatch(appActions.setAppInitialized({isInitialized: true}));
        }

    }


export const sentTransactionTC = (data: TransactionPostDataType): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(appActions.setAppStatus({status: "loading"}));
            const res = await TransactionsService.sentDepositInfo(data)

            dispatch(transactionActions.setNewTransaction(res.data));

            dispatch(appActions.setAppStatus({status: "succeeded"}));
        } catch
            (err) {
            dispatch(appActions.setAppStatus({status: "failed"}));
        } finally {
            dispatch(appActions.setAppInitialized({isInitialized: true}));
        }

    }

