import React, {memo, useEffect, useState} from "react";
import {Link, NavLink} from "react-router-dom";
import style from './Sidebar.module.scss';
import {setToken} from "../../utils/utils";
import {ReactComponent as More} from "../../assets/images/More.svg";
import {ReactComponent as Add} from "../../assets/images/Add.svg";
import {ReactComponent as House} from "../../assets/images/House.svg";
import {ReactComponent as Catalog} from "../../assets/images/Catalog.svg";
import {ReactComponent as Statistics} from "../../assets/images/Statistics.svg";
import {ReactComponent as Facebook} from "../../assets/images/Facebook.svg";
import {ReactComponent as Notify} from "../../assets/images/Notify.svg";
import {ReactComponent as Doc} from "../../assets/images/Doc.svg";
import {ReactComponent as FAQ} from "../../assets/images/Faq.svg";
import {ReactComponent as Profile} from "../../assets/images/Profile.svg";
import {ReactComponent as Wallet} from "../../assets/images/Wallet.svg";
import {ReactComponent as LogOut} from "../../assets/images/Logout.svg";
import {Collapse} from "@mui/material";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../state/store";
import {fetchProfileTC} from "../../state/profileReducer";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {fetchBalanceTC} from "../../state/balance-reducer";
import {modalsActions} from "../../state/modals-reducer";

export const SideBar = memo(function NavbarComponent() {
    const dispatch = useAppDispatch();

    const username = useSelector<AppRootStateType, string>(state => state.balance.username);
    const balance = useSelector<AppRootStateType, number>(state => state.balance.balance);

    useEffect(() => {
        dispatch(fetchBalanceTC())
    })
    const [open, setOpen] = useState(false)
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <div className={style.Wrapper}>
            <div className={style.Container}>
                <div className={style.WrapperInfo}>
                    <div className={style.LogoBlock}>
                        <p>Appster.rent</p>
                    </div>
                    <div className={style.WalletPreview}>
                        <div className={style.TopBlock}>
                            <NavLink to={'profile'} className={style.Name}>{username}</NavLink>
                            {/*<NavLink to={'profile'}>*/}
                            {/*    <More/>*/}
                            {/*</NavLink>*/}

                        </div>
                        <div className={style.BottomBlock}>
                            <p>Баланс</p>
                            <div>
                                <NavLink to={'balance'}>{balance === 0 ? '' : balance} USDT</NavLink>
                                <NavLink to={'balance'}>
                                    <Add onClick={() => {
                                        dispatch(modalsActions.changeStateOfReplenishingWallet(true))
                                    }}/>
                                </NavLink>

                            </div>
                        </div>
                    </div>
                    <div className={style.NavWrapper}>
                        <nav>
                            {/*<li className={style.ListTitle}>Главное меню</li>*/}
                            <NavLink to={'catalog'}
                                     className={({isActive}) => (isActive ? style.ActiveLink : style.Link)}>
                                <Catalog/>
                                Каталог приложений
                            </NavLink>
                            <NavLink to={'applications'}
                                     className={({isActive}) => (isActive ? style.ActiveLink : style.Link)}>
                                <House/>
                                Мои приложения
                            </NavLink>
                            <NavLink to={'statistics'}
                                     className={({isActive}) => (isActive ? style.ActiveLink : style.Link)}>
                                <Statistics/>
                                Статистика
                            </NavLink>
                            <li className={style.ItemList} onClick={handleClick}>
                                <span>
                                      <Facebook className={style.Icon}/>
                                FB токены / Логи
                                </span>
                                <span className={style.ExpandArrow}>
                                        {open ? <ExpandLess/> : <ExpandMore/>}
                                 </span>

                            </li>
                            <Collapse in={open} timeout="auto" unmountOnExit style={{width: '100%'}}>
                                <NavLink to={'tokens'}
                                         className={({isActive}) => (isActive ? style.ActiveSubLink : style.SubLink)}>
                                    <Statistics/>
                                    FB токены
                                </NavLink>
                                <NavLink to={'logs'}
                                         className={({isActive}) => (isActive ? style.ActiveSubLink : style.SubLink)}>
                                    <Statistics/>
                                    FB логи
                                </NavLink>
                            </Collapse>
                            <NavLink to={'pushNotifications'}
                                     className={({isActive}) => (isActive ? style.ActiveLink : style.Link)}>
                                <Notify/>
                                Push уведомления</NavLink>
                            <NavLink to={'docs'}
                                     className={({isActive}) => (isActive ? style.ActiveLink : style.Link)}>
                                <Doc/>
                                Документация
                            </NavLink>
                            <NavLink to={'faq'}
                                     className={({isActive}) => (isActive ? style.ActiveLink : style.Link)}>
                                <FAQ/>
                                FAQ
                            </NavLink>
                            {/*<li className={style.ListTitle}>Другое</li>*/}
                        </nav>
                    </div>
                </div>


            </div>
        </div>
    )
})
