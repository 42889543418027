import style from './Login.module.scss';
import {useFormik} from "formik";
import * as Yup from "yup";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {AppRootStateType} from "../../state/store";
import {TextFieldCustom} from "../../components/textFieldCustom/TextFieldCustom";
import {Switcher} from "../../components/customSwitcher/CustomSwitcher";
import {RequestStatusType} from "../../state/appReducer";
import {loginTC} from "../../state/auth-reducer";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {PasswordField} from "../../components/passwordField/PasswordField";


const FORM_VALIDATION = Yup.object().shape({
    email: Yup.string()
        .required('Required'),
    password: Yup.string()
        .required('Required').min(4),
})
const INITIAL_FORM_STATE = {
    email: '',
    password: ''
}

export const LoginPage = () => {
    const dispatch = useAppDispatch()
    const isLoggedIn = useSelector<AppRootStateType, boolean>(state => state.auth.isLoggedIn);
    const status = useSelector<AppRootStateType, RequestStatusType>(state => state.app.status);
    // const status: any = 'notfail'
    const [remeberMe, setRemeberMe] = useState(false)
    let navigate = useNavigate();

    const setRememberMeStatus = () => {
        setRemeberMe(!remeberMe);
    };
    useEffect(() => {
        if (status === 'incorrectValues') {
            formik.setErrors({
                email: 'incorrectValue',
                password: 'incorrectValue'
            })
        }
    }, [status]);
    useEffect(() => {
        if (isLoggedIn) {
            return navigate("/catalog");
        }
    }, [isLoggedIn]);

    const formik = useFormik({
        initialValues: INITIAL_FORM_STATE,
        validationSchema: FORM_VALIDATION,
        onSubmit: values => {
            // alert(JSON.stringify(values, null, 2));
            dispatch(loginTC(values.email, values.password));

        },
    })

    return (

        <div className={style.Wrapper}>
            <form onSubmit={formik.handleSubmit} className={style.Form}>
                <h1>Войти</h1>
                {/*{*/}
                {/*    status === 'incorrectValues'*/}
                {/*        ? <p className={style.ErrorDesc}>Вы ввели неверный логин или пароль</p>*/}
                {/*        : ''*/}
                {/*}*/}

                <TextFieldCustom formik={formik} title={'email'} placeholder={'E-mail'} mode={false}
                                 minRows={1}/>

                <PasswordField formik={formik} title={'password'} placeholder={'Пароль'} mode={false}
                                 minRows={1}/>

                <Switcher checked={remeberMe} handleChange={setRememberMeStatus}
                          labelPlacement={"end"}
                          label={"Запомнить меня"}/>
                {/*<button type={'button'} onClick={() => {*/}
                {/*    formik.setValues({email: 'test@cbmobile.by', password: '123chasto'});*/}
                {/*}}>*/}
                {/*    set values*/}
                {/*</button>*/}
                <button
                    className={`${style.BtnSubmit} ${(!formik.isValid || !formik.dirty) && style.BtnSubmitDisabled}`}
                    disabled={!formik.isValid || !formik.dirty}
                    type={'submit'}
                >
                    Войти
                </button>
            </form>
        </div>
    )
}