import axios, {InternalAxiosRequestConfig} from "axios";
import {getAccessToken, getRefreshToken, setToken} from "../../utils/utils";
import AuthService from "../services/AuthService";

const $api = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL,
})

$api.interceptors.request.use((config: InternalAxiosRequestConfig<any>) => {
    if (config.url === '/login') {
        return config
    }
    config.headers.Authorization = `Bearer ${getAccessToken()}`
    return config
})

$api.interceptors.response.use((config) => {
    config.headers.Authorization = `Bearer ${getAccessToken()}`
    return config
}, async (err) => {
    const originalRequest = err.config;

    if (err.response && err.response.status === 401 && err.config) {
        originalRequest._isRetry = true;
        try {
            const response = await AuthService.refresh(getRefreshToken())
            setToken(response.data)
            return $api.request(originalRequest);

        } catch (error) {
            console.log('пользователь не авторизован')
        }
        throw err;
    }
})
export default $api;