import {ChangeEvent} from "react";

type TokensType = {
    access: string
    refresh: string
}

export const getAccessToken = () => {
    return localStorage.getItem('access_token')
}
export const getRefreshToken = () => {
    return localStorage.getItem('refresh_token')
}

export const setToken = (data: TokensType) => {
    localStorage.setItem('access_token', data.access)
    return localStorage.setItem('refresh_token', data.refresh)
}
export const removeToken = () => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
}


function numberWithSpaces(x: string) {
    var parts = x.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
}

export const prettifyAmount = (amount: number) => numberWithSpaces((amount / 100).toFixed(2))

function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
}

export function formatDate(date: Date | string) {
    const perfDate = new Date(date)
    console.log(perfDate)
    return [
        padTo2Digits(perfDate.getDate()),
        padTo2Digits(perfDate.getMonth() + 1),
        perfDate.getFullYear(),
    ].join('.');
}

export function formatTime(date: Date | string) {
    const perfDate = new Date(date)
    console.log([
        perfDate.getHours(),
        perfDate.getMinutes(),
    ].join(':'))
    return [
        perfDate.getHours(),
        perfDate.getMinutes(),
    ].join(':');
}

export function formatDateForDownload(date: Date | string,) {
    const perfDate = new Date(date)
    return [
        perfDate.getFullYear(),
        padTo2Digits(perfDate.getMonth() + 1),
        padTo2Digits(perfDate.getDate()),
    ].join('-');
}

const onChangeTgIdValue = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length === 0) {
        console.log('required')
    }
    if (e.currentTarget.value.match(/(?!-)\D/)) {
        e.preventDefault()
        console.log('the field must contain only numbers')
    }
    // field.onChange(e.currentTarget.value.replace(/(?!-)\D/, ''))
    // formik.handleChange(e.currentTarget.value.replace(/(?!-)\D/, ''))
}
export const copyLink = async (title: string) => {
    await navigator.clipboard.writeText(title);
    setTimeout(() => {
        console.log('copied')
    }, 300)
    console.log('Uncopied')
}