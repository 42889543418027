import {Checkbox, FormControlLabel} from "@mui/material";
import React from "react";
import './CheckBox.scss';

interface Iprops {
    formik: any
    title: string
    label: string
}

export const CheckBoxCustom: React.FC<Iprops> = ({title, formik, label}) => {
    console.log(formik.values[title])
    return (
        <FormControlLabel
            id="outlined"
            className={'FormControl'}
            control={<Checkbox
                {...formik.getFieldProps(title)}
                name={title}
                checked={formik.values[title]}
                onChange={formik.handleChange}
                className={'CheckBox'}/>
            }
            label={label}
        />
    )
}