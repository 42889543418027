import './PasswordField.scss';
import {IconButton, InputAdornment, InputLabel, OutlinedInput, TextField} from "@mui/material";
import React, {useState} from "react";
import {ReactComponent as Copy} from "../../assets/images/Copy.svg";
import {Visibility, VisibilityOff} from '@mui/icons-material';

interface IProps {
    formik?: any
    title: string
    placeholder: string
    mode: boolean
    minRows?: number
}

export const PasswordField: React.FC<IProps> = ({formik, title, placeholder, mode, minRows}) => {
    const error = (formik.errors[title] && formik.touched[title]);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    return (
        <div className={`${'TextFieldPassword'} ${error && 'PassErrorInput'}`}>
            <OutlinedInput
                {...formik.getFieldProps(title)}
                id="standard-adornment-password"
                type={showPassword ? 'text' : 'password'}
                className={`${'PassInput'} ${minRows && 'InputTextArea'}
                                ${formik.values[title] && 'InputHas'} 
                                // ${error && 'PassErrorInput'}
                                ${mode && 'InputDisabled'} `}
                label={placeholder}
                onBlur={formik.handleBlur}
                placeholder={!formik.values[title] ? placeholder : ''}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                    </InputAdornment>
                }

            />
            {mode ? <button className={'CopyBtn'} type={'button'}><Copy/></button> : ''}

            {error ? <div className={'ErrorPass'}>{formik.errors[title]}</div> : null}
        </div>

    )
}