export enum sources {
    facebook = 'Facebook',
    stream = 'Stream'
}

export enum TaskStatuses {
    acitve = 'Активная',
    paused = 'Приостоновлена',
    error = 'Ошибка',
}

export const TaskStatuses2: any = {
    active: 'Активная',
    paused: 'Приостоновлена',
    error: 'Ошибка',
}
export const TransactionStatuses: any = {
    pending: 'Обработка',
    decline: 'Отмена',
    error: 'Ошибка',
    accept: 'Успешно',
}

export const chainArr = [{
    title: 'USDT TRC-20',
    value: 'usdt_trc_20'
}]
export const sourcesArr2 = [{
    title: 'Facebook',
    value: 'facebook'
}, {
    title: 'Stream',
    value: 'stream'
}]

export const sourcesArr = [
    {
        title: 'Facebook',
        value: 'facebook'
    },
    {
        title: 'Stream',
        value: 'stream'
    }]
