import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CampaignType} from "./models/types";
import {transactionInfoState} from "./modalEditRentState";

type EditAppRentStateType = null | CampaignType
type ReplenishingWalletStateType = boolean

type ModalsReducerStateType = {
    editAppRentState: EditAppRentStateType
    replenishingWallet: boolean
    transactionInfoState: string | null
}
const initialState: ModalsReducerStateType = {
    editAppRentState: null,
    replenishingWallet: false,
    transactionInfoState: null
}


const slice = createSlice({
    name: "modals",
    initialState,
    reducers: {
        changeStateOfEditAppRent: (state: ModalsReducerStateType, action: PayloadAction<EditAppRentStateType>) => {
            state.editAppRentState = action.payload
        },
        changeStateOfReplenishingWallet: (state: ModalsReducerStateType, action: PayloadAction<ReplenishingWalletStateType>) => {
            state.replenishingWallet = action.payload
        },
        changeStateOfTransactionInfo: (state: ModalsReducerStateType, action: PayloadAction<string | null>) => {
            console.log(state.transactionInfoState)
            state.transactionInfoState = action.payload
        },
    },
});

export const modalsReducer = slice.reducer;
export const modalsActions = slice.actions;



