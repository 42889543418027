import styled from "@emotion/styled";
import {copyLink} from "../../utils/utils";
import {ReactComponent as Copy} from '../../assets/images/Copy.svg';

type PropsType = {
    title: string
}

export const CopyComponent: React.FC<PropsType> = ({title}) => {
    return <>
        <Button onClick={() => copyLink(title)} type={'button'}>
            <Copy/>
        </Button>
    </>
}
const Button = styled.button`
    position: absolute;
    right: 20px;
    width: 16px;
    height: 20px;
    top: 50%;
    transform: translate(0, -50%);

    &:hover {
        > svg {
            > path {
                fill: rgba(136, 189, 255, 1);
            }
        }
    }
`;