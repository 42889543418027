import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "./store";
import {appActions} from "./appReducer";
import ProfileService from "./services/ProfileService";

export type NotificationsType = {
    amount: number
    user_index: number
}
export type NotifyType = {
    notifications: NotificationsType[]
    telegram_id: string
}

export type ProfileTypeResponse = {
    email: string
    send_balance_notifications: boolean
}
export type ProfileInitialStateType = {
    email: string
    send_balance_notifications: boolean
    notify: NotifyType
}

const initialState: ProfileInitialStateType = {
    email: '',
    send_balance_notifications: false,
    notify: {
        telegram_id: '',
        notifications: []
    }
}
type DeleteNotifyType = {
    id: number
}
const slice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        setProfileData: (state: ProfileInitialStateType, action: PayloadAction<{ data: ProfileTypeResponse }>) => {
            state.email = action.payload.data.email
            state.send_balance_notifications = action.payload.data.send_balance_notifications
        },
        setBalanceNotification: (state, action: PayloadAction<{ status: boolean }>) => {
            state.send_balance_notifications = action.payload.status
        },
        setNotifications: (state, action: PayloadAction<NotifyType>) => {
            state.notify = action.payload
        },
        addNotify: (state, action: PayloadAction<NotificationsType>) => {
            console.log(state.notify.notifications)
            state.notify.notifications.push(action.payload);
            console.log(state.notify.notifications)
        },
        changeTitleOfNotify: (state: ProfileInitialStateType, action: PayloadAction<{
            title: number,
            userId: number
        }>) => {
            const index = state.notify.notifications.findIndex(n => n.user_index === action.payload.userId)
            state.notify.notifications[index].amount = action.payload.title
        },
        deleteNotify: (state, action: PayloadAction<DeleteNotifyType>): any => {
            // debugger
            // const index = state.notify.notifications.findIndex((u) => u.user_index === action.payload.id);
            // if (index !== -1) state.notify.notifications.splice(index, 1);
            state.notify.notifications = state.notify.notifications.filter((n) => n.user_index !== action.payload.id);
            // debugger
            // state.notify.notifications.splice(state.notify.notifications.findIndex((arrow) => arrow.user_index === action.payload.id), 1);
        },
    },
});

export const profileReducer = slice.reducer;
export const profileActions = slice.actions;

// const index = state.findIndex((u) => u.id === action.payload.userId);
// if (index !== -1) state.splice(index, 1);

export const fetchProfileTC = (): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(appActions.setAppStatus({status: "loading"}));
            const res = await ProfileService.fetchProfile()
            dispatch(profileActions.setProfileData({data: res.data}));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        } catch
            (err) {
            dispatch(appActions.setAppStatus({status: "failed"}));
        } finally {
            dispatch(appActions.setAppInitialized({isInitialized: true}));
        }

    }

export const sentBalanceNotificationsTC = (status: boolean): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(appActions.setAppStatus({status: "loading"}));
            const res = await ProfileService.sentStatusOfBalanceNotifications(status)
            dispatch(profileActions.setBalanceNotification({status: res.data.send_balance_notifications}));

            dispatch(appActions.setAppStatus({status: "succeeded"}));
        } catch
            (err) {
            dispatch(appActions.setAppStatus({status: "failed"}));
        } finally {
            dispatch(appActions.setAppInitialized({isInitialized: true}));
        }

    }

export const fetchProfileNotificationsTC = (): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(appActions.setAppStatus({status: "loading"}));
            const res = await ProfileService.fetchProfileNotifications()

            dispatch(profileActions.setNotifications(res.data));

            dispatch(appActions.setAppStatus({status: "succeeded"}));
        } catch
            (err) {
            dispatch(appActions.setAppStatus({status: "failed"}));
        } finally {
            dispatch(appActions.setAppInitialized({isInitialized: true}));
        }

    }

export const setProfileNotificationsTC = (data: NotifyType): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(appActions.setAppStatus({status: "loading"}));
            const res = await ProfileService.sentProfileNotifications(data)

            dispatch(profileActions.setNotifications(res.data));

            dispatch(appActions.setAppStatus({status: "succeeded"}));
        } catch
            (err) {
            dispatch(appActions.setAppStatus({status: "failed"}));
        } finally {
            dispatch(appActions.setAppInitialized({isInitialized: true}));
        }

    }

