import {FormControl, InputLabel, MenuItem, Select} from "@mui/material"
import './SelectCustom.scss';
import React, {useState} from "react";
import {sourcesArr} from "../../state/constants/constants";

interface IProps {
    formik: any
    title: string
    placeholder: string
    mode: boolean
    arr?: typeof sourcesArr
}

export const SelectCustom: React.FC<IProps> = ({formik, title, placeholder, arr}) => {
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(!open)
    }

    console.log(arr)

    return (
        <>
            <FormControl fullWidth className={`${'FormControl'} ${formik.values[title] && 'SelectHas'}`}>
                <InputLabel className={'Label'}>{placeholder}</InputLabel>
                <Select
                    {...formik.getFieldProps(title)}
                    id="outlined"
                    label={placeholder}
                    placeholder={placeholder}
                    className={`${'Select'} ${open && 'SelectOpened'}`}
                    open={open}
                    onClose={handleOpen}
                    onOpen={handleOpen}
                >
                    {arr && arr.map((type, index) => (

                        <MenuItem value={type.value} key={index} className={'MenuItem'}>
                            {type.title}
                        </MenuItem>
                        // <MenuItem value={type.value.toLowerCase()} key={type.value} className={'MenuItem'}>
                        //     {type.value}
                        // </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    )
}