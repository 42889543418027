import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "./store";
import {appActions} from "./appReducer";
import {CampaignType, SubmittedCampaignsСhangesType, TakeAppRentDataType} from "./models/types";
import CampaignsService from "./services/CampaingsService";
import {modalsActions} from "./modals-reducer";


const initialState: CampaignType[] = []

export type AppsInitialStateType = typeof initialState;

const slice = createSlice({
    name: "capmaigns",
    initialState,
    reducers: {
        setAllCampaigns: (state: CampaignType[], action: PayloadAction<{ data: CampaignType[] }>) => {
            return action.payload.data
        },
        setDefiniteCampaign: (state: CampaignType[], action: PayloadAction<{ data: CampaignType, appId: string }>) => {
            const index = state.findIndex((t) => t.id === action.payload.appId);
            if (index !== -1) {
                state[index] = {...action.payload.data};
            }
        },
    },
});

export const capmaignsReducer = slice.reducer;
export const capmaignsActions = slice.actions;


export const takeAppRentTC = (data: TakeAppRentDataType): AppThunk<Promise<CampaignType | void>> =>
    (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        return CampaignsService.sendTakenCampaign(data).then(res => {
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            return res.data
        }).catch(err => {
            dispatch(appActions.setAppStatus({status: "failed"}));
        })

    }
export const fetchAllCampaignsTC = (): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(appActions.setAppStatus({status: "loading"}));
            const res = await CampaignsService.fetchAllCampaigns()
            dispatch(capmaignsActions.setAllCampaigns({data: res.data}));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        } catch (err) {
            console.log('---------------------------')
            dispatch(appActions.setAppStatus({status: "failed"}));
        } finally {
            dispatch(appActions.setAppInitialized({isInitialized: true}));
        }
    }


export const setCampaignChangesTC = (id: string, data: SubmittedCampaignsСhangesType): AppThunk<any> =>
    (dispatch) => {

        dispatch(appActions.setAppStatus({status: "loading"}));

        CampaignsService.sentCampaignChanges(id, data).then(res => {

            dispatch(appActions.setAppStatus({status: "succeeded"}));
            dispatch(fetchAllCampaignsTC())
            console.log(res.data)
        }).catch(err => {
            dispatch(appActions.setAppStatus({status: "failed"}));
        })

    }


export const fetchDefiniteCampaignTC = (id: string, data: any): AppThunk =>
    (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));

        CampaignsService.fetchDefiniteCampaign(id).then(res => {
            dispatch(capmaignsActions.setDefiniteCampaign({data: res.data, appId: id}));
            dispatch(appActions.setAppStatus({status: "succeeded"}));

        }).catch(err => {
            dispatch(appActions.setAppStatus({status: "failed"}));
        })

    }
