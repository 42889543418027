import style from './CustomSwitcher.module.scss';
import {FormControlLabel, Switch} from "@mui/material";
import React, {ChangeEvent} from "react";

type SwitcherType = {
    checked: boolean
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void
    labelPlacement: 'end' | "start"
    label: string
}

export const Switcher: React.FC<SwitcherType> = ({handleChange, checked, label, labelPlacement}) => {


    return (
        <FormControlLabel
            value="end"
            control={<Switch checked={checked}
                             onChange={handleChange}
                             className={`${style.Switch} ${checked && style.SwitchChecked} 
                                        ${labelPlacement === 'end' && checked && style.LoginChecked}
                                        `}
            />}
            label={label}
            labelPlacement={labelPlacement}
            className={style.FormControlLabel}
        />
    )
}