import React, {ChangeEvent, useEffect, useState} from "react";
import {useFormik} from "formik";
import {Accordion, AccordionDetails, AccordionSummary, Modal} from "@mui/material";
import style from "./ModalReplenishingWallet.module.scss";
import {TextFieldCustom} from "../textFieldCustom/TextFieldCustom";
import {ReactComponent as Cross} from "../../assets/images/Cross.svg";
import {ReactComponent as Info} from "../../assets/images/Info.svg";
import {SelectCustom} from "../selectCustom/SelectCustom";
import * as Yup from "yup";
import {ErrorModal} from "../errorModal/ErrorModal";
import {fetchDepositInfoTC, sentTransactionTC} from "../../state/transactions-reducer";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../state/store";
import {replenishingState} from "../../state/modalEditRentState";
import {modalsActions} from "../../state/modals-reducer";
import {BonusesType} from "../../state/services/TransactionsService";
import {TextFieldControlledByHands} from "../textFieldControlledHands/TextFieldControlledByHands";
import {chainArr} from "../../state/constants/constants";


const FORM_VALIDATION = Yup.object().shape({
    chain: Yup.string()
        .required('Required'),
    hash: Yup.string()
        .required('Required'),
    address: Yup.string()
        .required('Required'),
})

const INITIAL_FORM_STATE = {
    chain: '',
    hash: '',
    address: ''
}

export const ModalReplenishingWallet: React.FC = () => {
    const address = useSelector<AppRootStateType, string>(state => state.transactions.info.address);
    const bonuses = useSelector<AppRootStateType, BonusesType[]>(state => state.transactions.info.bonuses);
    const dispatch = useAppDispatch();

    const stateOfModal = useSelector(replenishingState);

    const formik = useFormik({
        initialValues: INITIAL_FORM_STATE,
        validationSchema: FORM_VALIDATION,
        onSubmit: values => {
            // alert(JSON.stringify({
            //     chain: formik.values.chain.toLowerCase(),
            //     hash: formik.values.hash
            // }, null, 2));

            dispatch(sentTransactionTC({
                chain: formik.values.chain,
                hash: formik.values.hash
            }))
        },
    });

    useEffect(() => {
        if (stateOfModal) {
            dispatch(fetchDepositInfoTC())
        }
    }, [stateOfModal]);

    //set Values to form
    useEffect(() => {
        formik.resetForm({
            values: {
                chain: chainArr[0].value,
                hash: '',
                address: address,
            }
        })
    }, [address]);


    const [openErr, setOpenErr] = useState(false)

    const handleCloseErr = () => {
        setOpenErr(!openErr)
    }
    const toggleModal = () => {
        formik.resetForm()
        dispatch(modalsActions.changeStateOfReplenishingWallet(!stateOfModal))
    }

    const [paymentAmount, setPaymentAmount] = useState('')
    const [bonusValue, setBonusValue] = useState<number>(0);
    const [errorPayment, setErrorPayment] = useState<string | null>(null)

    const sorted = [...bonuses].sort((a, b) => b.amount - a.amount);
    console.log(sorted)

    const onChangePaymentAmount = (e: ChangeEvent<HTMLInputElement>) => {
        setPaymentAmount(e.currentTarget.value)
    }
    const onChangeTgIdValue = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.value !== null) {
            setErrorPayment(null)
        }
        if (e.currentTarget.value.length === 0) {
            setErrorPayment('required')
            setBonusValue(0);
        }
        if (e.currentTarget.value.match(/(?!-)\D/)) {
            e.preventDefault()
            setErrorPayment('the field must contain only numbers')
        }
        setPaymentAmount(e.currentTarget.value.replace(/(?!-)\D/, ''));

        sorted.some((s) => {
            if (+e.currentTarget.value >= s.amount) {
                setBonusValue(+e.currentTarget.value * s.bonus / 100);
                return true
            }
        })
    }

    const onBlurTgIdHandler = () => {
        if (paymentAmount.length === 0) {
            setErrorPayment('required')
        }
        if (!paymentAmount.match(/(?!-)\D/)) {
            setErrorPayment(null)
        }
    }

    return (
        <Modal open={stateOfModal} className={style.Canvas}>
            <div className={style.Wrapper}>
                <div className={style.Container}>
                    <ErrorModal open={openErr} handleClose={handleCloseErr}/>
                    <div className={style.TitleBlock}>
                        <h3 className={style.Title}>Пополнение кошелька</h3>
                        <button onClick={toggleModal}>
                            <Cross/>
                        </button>
                    </div>
                    <form onSubmit={formik.handleSubmit} className={style.Form}>
                        <div className={style.SectionForm}>
                            <SelectCustom formik={formik}
                                          placeholder={'Способ платежа'}
                                          title={'chain'}
                                          mode={true}
                                          arr={chainArr}
                            />
                            <TextFieldCustom formik={formik}
                                             placeholder={'Адрес пополнения'}
                                             title={'address'}
                                             mode={true}
                            />
                            <TextFieldControlledByHands placeholder={'Сумма платежа'}
                                                        title={'chooseCompany'}
                                                        mode={false}
                                                        onBlur={onBlurTgIdHandler}
                                                        error={errorPayment}
                                                        value={paymentAmount}
                                                        onChange={onChangeTgIdValue}
                            />
                            <TextFieldControlledByHands placeholder={'Бонус за пополнение'}
                                                        title={'bonus'}
                                                        mode={false}
                                                        onBlur={onBlurTgIdHandler}
                                                        error={null}
                                                        value={bonusValue.toString()}
                                                        readOnly={true}
                                                        onChange={onChangePaymentAmount}
                            />
                            {/*<TextFieldCustom formik={formik}*/}
                            {/*                 placeholder={'Сумма платежа'}*/}
                            {/*                 title={'chooseCompany'}*/}
                            {/*                 mode={false}*/}
                            {/*/>*/}
                            {/*<TextFieldCustom formik={formik}*/}
                            {/*                 placeholder={'Бонус за пополнение'}*/}
                            {/*                 title={'bonus'}*/}
                            {/*                 mode={false}*/}
                            {/*/>*/}
                            <Accordion className={style.AccordionSummary}>
                                <AccordionSummary
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <p className={style.SubTitle}>
                                        Как получить бонус?
                                        <Info className={style.Info}/>
                                    </p>
                                </AccordionSummary>
                                <AccordionDetails className={style.AccordionDetails}>
                                    <div className={style.HintBlock}>
                                        <h4>Как получить бонус?</h4>
                                        <p>При единовременном пополнение баланса на любую из списка ниже сумму, Вы
                                            получите
                                            бонус к вашему пополнению.</p>


                                        <div className={style.ProcentsBlock}>
                                            {
                                                bonuses.map((bonuse, i) => {
                                                    return (
                                                        <p>От ${bonuse.amount} - {bonuse.bonus}%</p>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <TextFieldCustom formik={formik}
                                             placeholder={'Tx Hash'}
                                             title={'hash'}
                                             mode={false}
                            />
                            <div className={style.HintBlock}>
                                <h4>Как пополнить баланс?</h4>
                                <ul>
                                    <li>1. Для пополнения баланса введите желаемую сумму.</li>
                                    <li>2. Скопируйте кошелек и отправьте на него выбранную сумму.</li>
                                    <li>3. В поле "Подтверждение транзакции" вставьте Tx Hash транзакции и
                                        нажмите
                                        "Отправить платеж"
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <button
                            className={`${style.BtnSubmit} ${(!formik.isValid || !formik.dirty) && style.BtnSubmitDisabled}`}
                            disabled={!formik.isValid || !formik.dirty}
                            type={'submit'}>
                            Отправить платеж
                        </button>
                    </form>
                </div>

            </div>

        </Modal>
    )
}