import './TextField.scss';
import {TextField} from "@mui/material";
import React, {ChangeEvent} from "react";
import {ReactComponent as Copy} from "../../assets/images/Copy.svg";
import {CopyComponent} from "../copy/CopyComponent";

interface IProps {
    title: string
    placeholder: string
    error?: string | null
    mode: boolean
    readOnly?: boolean
    minRows?: number
    value: string
    onBlur?: () => void
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export const TextFieldControlledByHands: React.FC<IProps> = ({
                                                                 value,
                                                                 onBlur,
                                                                 onChange,
                                                                 error,
                                                                 placeholder,
                                                                 mode,
                                                                 readOnly,
                                                                 minRows
                                                             }) => {

    console.log(value)

    return (
        <div className={'TextFieldWr'}>
            <TextField
                id="outlined"
                value={value}
                InputProps={{
                    readOnly: readOnly,
                }}
                onChange={onChange}
                onPaste={(e) => {
                    if (e.clipboardData.getData('text').match(/(?!-)\D/)) {
                        e.preventDefault()
                        console.log('the field must contain only numbers')
                    }
                }}

                className={`${'Input'} ${minRows && 'InputTextArea'}
                                ${value && 'InputHas'} 
                                 ${error && 'ErrorInput'}
                                ${mode && 'InputDisabled'} `}
                label={placeholder}
                disabled={mode}
                onBlur={onBlur}
                placeholder={placeholder}
                multiline
            />
            {error ? <div className={'Error'}>{error}</div> : null}
            {mode ? <CopyComponent title={value}/> : ''}
        </div>

    )
}