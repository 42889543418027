import $api from "../http";
import {AxiosResponse} from "axios";

type BalanceTypeResponse = {
    balance: number
    username: string
}
export default class BalanceService {
    static async fetchBalance(): Promise<AxiosResponse<BalanceTypeResponse>> {
        return $api.get('/balance')
    }
}

