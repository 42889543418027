import style from "./Error.module.scss";
import {Modal} from "@mui/material";
import {ReactComponent as Err} from "../../assets/images/Err.svg";

interface Props {
    open: boolean
    handleClose: () => void
}


export const ErrorModal: React.FC<Props> = ({open, handleClose}) => {

    return (
        <Modal open={open} onClose={handleClose} className={style.Canvas}>
            <div className={style.Container}>
                <Err/>
                <p className={style.Title}>Ошибка!</p>
                <p className={style.Desc}>При отправке тестового события возникла ошибка.</p>
                <button className={style.ApplyBtn}>Подробнее</button>
                <button className={style.CancelBtn}>Редактировать</button>
            </div>
        </Modal>
    )
}
