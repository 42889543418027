import './TextField.scss';
import {TextField} from "@mui/material";
import React from "react";
import {ReactComponent as Copy} from "../../assets/images/Copy.svg";
import {CopyComponent} from "../copy/CopyComponent";

interface IProps {
    formik?: any
    title: string
    placeholder: string
    mode: boolean
    minRows?: number
}

export const TextFieldCustom: React.FC<IProps> = ({formik, title, placeholder, mode, minRows}) => {
    const error = (formik?.errors[title] && formik?.touched[title]);

    return (
        <div className={'TextFieldWr'}>
            <TextField
                {...formik?.getFieldProps(title)}
                type={'text'}
                className={`${'Input'} ${minRows && 'InputTextArea'}
                                ${formik?.values[title] && 'InputHas'} 
                                ${error && 'ErrorInput'}
                                ${mode && 'InputDisabled'} `}
                label={placeholder}
                onBlur={formik?.handleBlur}
                disabled={mode}
                placeholder={!formik?.values[title] ? placeholder : ''}
                multiline
                minRows={minRows && minRows}
            />
            {mode ? <CopyComponent title={formik?.values[title]}/> : ''}

            {error ? <div className={'Error'}>{formik?.errors[title]}</div> : null}
        </div>

    )
}