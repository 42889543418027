import React, {Suspense, useEffect} from 'react';
import './App.css';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {ProtectedRoute} from "./protectedRoute/ProtectedRoute";
import DefaultLayout from "./layout/DefaultLayout";
import {LoginPage} from "./pages/login/Login";
import './assets/styles/reset.css';
import "react-datepicker/dist/react-datepicker.css";
import {getAccessToken} from "./utils/utils";
import {useAppDispatch} from "./hooks/useAppDispatch";
import {checkAuthTC} from "./state/auth-reducer";
import {useSelector} from "react-redux";
import {AppRootStateType} from "./state/store";
import {RequestStatusType} from "./state/appReducer";
import {CircularProgress} from "@mui/material";
import {fetchProfileTC} from "./state/profileReducer";


function App() {
    const dispatch = useAppDispatch()

    const isInitialized = useSelector<AppRootStateType, boolean>(state => state.app.isInitialized)

    useEffect(() => {
        dispatch(checkAuthTC())
    }, [])

    return (

        <BrowserRouter>
            <Suspense>
                {
                    isInitialized ? <Routes>
                        <Route path="/" element={<Navigate to="/login" replace/>}/>
                        <Route path={'/login'} element={<LoginPage/>}/>
                        <Route path="*" element={
                            <ProtectedRoute>
                                <DefaultLayout/>
                            </ProtectedRoute>
                        }/>
                    </Routes> : <CircularProgress/>
                }

            </Suspense>
        </BrowserRouter>
    );
}

export default App;
