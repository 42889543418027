import {Modal} from "@mui/material";
import style from './ModalAppsEditRent.module.scss';
import {ReactComponent as Cross} from "../../assets/images/Cross.svg";
import {useFormik} from "formik";
import React, {memo, useEffect} from "react";
import * as Yup from "yup";
import {TextFieldCustom} from "../textFieldCustom/TextFieldCustom";
import {SelectCustom} from "../selectCustom/SelectCustom";
import {CheckBoxCustom} from "../checkBox/CheckBoxCustom";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {useSelector} from "react-redux";
import {modalEditRentState} from "../../state/modalEditRentState";
import {modalsActions} from "../../state/modals-reducer";
import {setCampaignChangesTC, takeAppRentTC} from "../../state/Campaings-reducer";
import {sourcesArr} from "../../state/constants/constants";

const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string()
        .required('Required'),
    id: Yup.string()
        .required('Required'),
    app_link: Yup.string()
        .required('Required'),
    status: Yup.string()
        .required('Required'),
    registration_postback_link: Yup.string()
        .required('Required'),
    deposit_postback_link: Yup.string()
        .required('Required'),
    source: Yup.string()
        .required('Required'),
    install_link: Yup.string()
        .required('Required'),
    boost_opt: Yup.boolean(),
    send_postback: Yup.boolean(),
})

const INITIAL_FORM_STATE = {
    name: '',
    id: '',
    app_link: '',
    status: '',
    registration_postback_link: '',
    deposit_postback_link: '',
    source: '',
    install_link: '',
    boost_opt: false,
    send_postback: false,
}

export const ModalAppsEditRent: React.FC = memo(function ModalAppsEditRent() {
        const dispatch = useAppDispatch();

        const campaign = useSelector(modalEditRentState);
        const campaignId = campaign?.id


        const formik = useFormik({
            initialValues: INITIAL_FORM_STATE,
            validationSchema: FORM_VALIDATION,
            onSubmit: async (values, setSubmitting) => {
                if (!campaignId) {
                    console.log("campaignId");
                    return
                }
                const obj = {
                    name: values.name,
                    source: values.source,
                    app_link: values.app_link,
                    boost_opt: values.boost_opt,
                    send_postback: values.send_postback
                }
                dispatch(setCampaignChangesTC(campaignId, obj))
                console.log(formik.isSubmitting)
                // alert(JSON.stringify(obj, null, 2));
            },
        });

        useEffect(() => {
            if (!campaign) return
            formik.resetForm({
                values: {
                    name: campaign.name,
                    id: campaign.id,
                    app_link: campaign.app_link,
                    status: campaign.status,
                    registration_postback_link: campaign.registration_postback_link,
                    deposit_postback_link: campaign.deposit_postback_link,
                    source: campaign.source,
                    install_link: campaign.install_link,
                    boost_opt: campaign.boost_opt,
                    send_postback: campaign.send_postback,
                }
            })
            console.log(formik.values)
        }, [campaign]);

        console.log(formik.values)

        const handleClose = () => {
            dispatch(modalsActions.changeStateOfEditAppRent(null))
        }
        return (
            <Modal open={!!campaign} className={style.Canvas}>
                <div className={style.Wrapper}>
                    <div className={style.Container}>
                        <div className={style.TitleBlock}>
                            <h3 className={style.Title}>{campaign?.app.title}</h3>
                            <button onClick={handleClose}>
                                <Cross/>
                            </button>
                        </div>
                        <form onSubmit={formik.handleSubmit} className={style.Form}>
                            <div className={style.SectionForm}>
                                <p className={style.SubTitle}>Основное</p>
                                <TextFieldCustom formik={formik}
                                                 placeholder={'Название кампании'}
                                                 title={'name'}
                                                 mode={false}
                                />
                                <TextFieldCustom formik={formik}
                                                 placeholder={'ID кампании'}
                                                 title={'id'}
                                                 mode={true}
                                />
                                <SelectCustom formik={formik}
                                              placeholder={'Источник'}
                                              title={'source'}
                                              mode={true}
                                              arr={sourcesArr}
                                />
                                <TextFieldCustom formik={formik}
                                                 placeholder={'Ссылка на офер'}
                                                 title={'app_link'}
                                                 mode={false}
                                />
                                <TextFieldCustom formik={formik}
                                                 placeholder={'Статус'}
                                                 title={'status'}
                                                 mode={false}
                                />
                            </div>
                            <div className={style.SectionForm}>
                                <p className={style.SubTitle}>Постбеки</p>
                                <TextFieldCustom formik={formik}
                                                 placeholder={'Ссылка на постбеки (регистрации)'}
                                                 title={'registration_postback_link'}
                                                 mode={true}
                                />
                                <TextFieldCustom formik={formik}
                                                 placeholder={'Ссылка на постбеки (депозит)'}
                                                 title={'deposit_postback_link'}
                                                 mode={true}
                                />
                            </div>
                            <div className={style.SectionForm}>
                                <p className={style.SubTitle}>Дополнительно</p>
                                <TextFieldCustom formik={formik}
                                                 placeholder={'Пример ссылки'}
                                                 title={'install_link'}
                                                 mode={true}
                                                 minRows={2}
                                />
                                <CheckBoxCustom title={'send_postback'}
                                                label={'Отправлять постбеки'} formik={formik}/>
                                <CheckBoxCustom title={'boost_opt'}
                                                label={'Ускоренная оптимизация'} formik={formik}/>
                            </div>
                            <button
                                className={`${style.BtnSubmit} ${(!formik.isValid || !formik.dirty || formik.isSubmitting) && style.BtnSubmitDisabled}`}
                                disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                                type={'submit'}>
                                Сохранить
                            </button>
                        </form>

                    </div>
                </div>

            </Modal>
        )
    }
)