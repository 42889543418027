import $api from "../http";
import {AxiosResponse} from "axios";
import {AppType} from "../models/types";


export default class AppsService {
    static async fetchApps(): Promise<AxiosResponse<AppType[]>> {
        return $api.get('/apps')
    }
}

