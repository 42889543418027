import React from 'react'
import style from './DefaultLayout.module.scss';
import {LinearProgress} from "@mui/material";
import {useSelector} from "react-redux";
import MainContent from "../components/mainContent/MainContent";
import {SideBar} from "../components/sideBar/SideBar";
import {AppRootStateType} from "../state/store";
import {RequestStatusType} from "../state/appReducer";
import {ModalAppsEditRent} from "../components/modalAppsEditRent/ModalAppsEditRent";
import {ModalReplenishingWallet} from "../components/modalReplinishungWalle/ModalReplenishingWallet";
import {ModalTransactionInfo} from "../components/modalTransactionInfo/ModalTransactionInfo";


const DefaultLayout = () => {
    const status = useSelector<AppRootStateType, RequestStatusType>(state => state.app.status)

    return (
        <div className={style.Wrapper}>
            <SideBar/>
            <ModalAppsEditRent/>
            <ModalReplenishingWallet/>
            <ModalTransactionInfo/>
            {
                status === 'loading' && <LinearProgress className={style.Linear}/>
            }
            <MainContent/>
        </div>
    )
}

export default DefaultLayout