import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "./store";
import {appActions} from "./appReducer";
import ProfileService from "./services/ProfileService";
import BalanceService from "./services/BalanceService";
import {setToken} from "../utils/utils";

type BalanceResponseType = {
    balance: number
    username: string
}
const initialState = {
    balance: 0,
    username: ''
}

export type BalanceInitialStateType = typeof initialState;

const slice = createSlice({
    name: "balance",
    initialState,
    reducers: {
        setProfileData: (state: BalanceInitialStateType, action: PayloadAction<{ data: BalanceResponseType }>) => {
            return action.payload.data
        },
    },
});

export const balanceReducer = slice.reducer;
export const balanceActions = slice.actions;

export const fetchBalanceTC = (): AppThunk =>
    (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        BalanceService.fetchBalance().then(res => {
            dispatch(balanceActions.setProfileData({data: res.data}));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        }).catch(err => {
            dispatch(appActions.setAppStatus({status: "failed"}));
            console.log('-----------------------------')
            console.log(err.message)
        })

    }

