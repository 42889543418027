import {AnyAction, combineReducers} from "redux";
import {authReducer} from "./auth-reducer";
import {appReducer} from "./appReducer";
import {configureStore, ThunkAction, ThunkDispatch} from "@reduxjs/toolkit";
import {profileReducer} from "./profileReducer";
import {balanceReducer} from "./balance-reducer";
import {appsReducer} from "./apps-reducer";
import {capmaignsReducer} from "./Campaings-reducer";
import {modalsReducer} from "./modals-reducer";
import {transactionReducer} from "./transactions-reducer";
import {statisticsReducer} from "./statistics-reducer";


export const rootReducer = combineReducers({
    auth: authReducer,
    app: appReducer,
    profile: profileReducer,
    balance: balanceReducer,
    apps: appsReducer,
    campaings: capmaignsReducer,
    modals: modalsReducer,
    transactions: transactionReducer,
    statistics: statisticsReducer,
})
export type AppRootStateType = ReturnType<typeof rootReducer>

export const store = configureStore({
    reducer: rootReducer
})

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppRootStateType, unknown, AnyAction>;

export type AppDispatch = ThunkDispatch<AppRootStateType, unknown, AnyAction>;

// @ts-ignore
window.store = store